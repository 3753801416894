
html {
  scroll-behavior: smooth;
  overflow-x: hidden;
  font-size: 100%;
}
/* Override the default color of the hamburger icon */
.navbar-toggler {
  border-color: rgba(255, 255, 255, 0.1) !important; /* Optional: Change the border color of the button */
}

.navbar-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=UTF8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba%28255, 255, 255, 1%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E") !important;
}

.hero-screen-full {
  position: relative;
  z-index: 1;
  padding-top: 60px; /* Ensure space at the top */
}

nav.navbar {
  position: relative;
  z-index: 2;
}

.hero {
  margin-top: 32em; /* Adjust as necessary to ensure visibility */
}

@media (max-width: 768px) {
  .hero {
    margin-top: 15em /* Increase top margin for smaller screens */
  }

  .hero-screen-full {
    padding-top: 80px; /* Ensure space at the top for mobile */
  }
}

@media (orientation: landscape) and (max-width: 768px) {
  .hero {
    margin-top: 32em; /* Increase top margin for landscape orientation */
  }

  .hero-screen-full {
    padding-top: 100px; /* Ensure space at the top for landscape mode */
  }
}

a:hover {
  text-decoration: none;
}

.hero-screen-full {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#technologiesSection a span {
  position: absolute;
  top: 86vh;
  left: 50%;
  width: 24px;
  height: 24px;
  margin-left: -12px;
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-animation: sdb07 2s infinite;
  animation: sdb07 2s infinite;
  opacity: 0;
  box-sizing: border-box;
}
#technologiesSection a span:nth-of-type(1) {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
#technologiesSection a span:nth-of-type(2) {
  top: 88vh;
  -webkit-animation-delay: .15s;
  animation-delay: .15s;
}
#technologiesSection a span:nth-of-type(3) {
  top: 90vh;
  -webkit-animation-delay: .3s;
  animation-delay: .3s;
}
@-webkit-keyframes sdb07 {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes sdb07 {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* Media Queries for mobile */
@media only screen and (max-width: 600px) {
  html { font-size: 100%; }
  img.myImage { width: 95%; height: 40%; margin-bottom: 0vh; }
  .techCardColumn {
    padding: 10px;
  }
  .scroller {
    visibility: hidden;
  }
  .socialHandleIcon {
    margin-bottom: 20px;
    margin-right: 10px;
  }
  .socialHandleSection {
  }
  .nameColumn {
    margin-top: 20px;
    height: 23vh;
  }
  .nameText {
    font-size: 7vw;
    text-align: center;
  }
  .helloText {
    color: #ABABAB;
    font-size: 3vw;
    margin-top: 10px;
  }
  .descriptionText {
    font-size: 5vw;
  }
  .techHeading {
    margin-top: 15px;
    font-size: 5vw;
  }
  .svgTechIcon {
    height: 10vw;
    width: 10vw;
  }
  .socialHandleIconBottom {
    height: 40px;
    margin-right: 3vw;
  }
  #socialMediaAtBottom {
    text-align: center;
    margin-bottom: 30px;
  }
  .reachMeOutTxt {
    font-size: 5vw;
    text-align: left;
  }
  .firebaseIcon {
    height: 9vw;
    width: 6vw;
  }
  .deviconIcon {
    font-size: 9vw;
    padding: 0px;
  }
  .techName {
    font-size: 3vw;
    text-align: center;
  }
}

@media only screen and (min-width: 600px) {
  img.myImage { width: 30vw; height: 30vw; margin-top: 10px }
  .techCardColumn {
    padding: 10px;
  }
  .socialMediaHandle {
    margin-top: 0vw;
  }
  .heroRow {
    height: 0vh;
  }
  .nameText {
    font-size: 45px;
    line-height: 1;
  }
  .descriptionText {
    font-size: 30px;
  }
  #socialMediaAtBottom {
    visibility: hidden;
  }
  #techSection {
    margin-top: 100vh;
  }
  .firebaseIcon {
    height: 60px;
    width: 45px;
  }
  .techHeading {
    font-size: 24px;
  }
  .svgTechIcon {
    height: 60px;
    width: 60px;
  }
  .deviconIcon {
    font-size: 40px;
  }
  .technologies {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

@media only screen and (min-width: 992px) {
  img.myImage { width: 80%; height: 40%; }
  .techCardColumn {
    padding: 30px
  }
  .heroRow {
    height: 100vh;
  }
  .nameColumn {
    margin-top: 70px;
  }
  .svgTechIcon {
    height: 60px;
    width: 60px;
  }
  #socialMediaAtBottom {
    visibility: hidden;
  }
  .firebaseIcon {
    height: 60px;
    width: 45px;
  }
  .deviconIcon {
    font-size: 40px;
  }
  .techHeading {
    font-size: 24px;
  }
  #techSection {
    margin-top: 0;
  }
}

.socialHandleSection {

}

.socialHandleIcon {
  height: 50px;
  width: 50px;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 10px;
}

.descriptionText {
  color: black;
  font-family: "Varela Round";
}

.nameText {
  color: white;
  font-weight: bold;
  font-family: "Varela Round"
}


.helloText {
  color: #ABABAB;
}

.techHeading {
  font-family: "Varela Round";
  font-weight: bold;
}

.techName {
  color: white;
  visibility: hidden;
}

.settings-page .video-container {
    margin: 20px 0;
    text-align: center;
}

.settings-page .video-container video {
    max-width: 100%;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

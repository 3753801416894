.StripeElement {
    width: 100%;
    padding: 10px;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    background-color: white;
}

.StripeElement--focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
}

.StripeElement--invalid {
    border-color: #fa755a;
}

.StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
}
@media (max-width: 768px) {
    .table-responsive thead {
        display: none; /* Hide the table header */
    }

    .table-responsive tr {
        display: block;
        margin-bottom: 1rem;
        border: 1px solid #dee2e6;
        padding: 0.5rem;
    }

    .table-responsive td {
        display: inline-block;
        justify-content: space-between;
        align-items: center;
        padding: 0.5rem;
        text-align: left;
        border: none;
        width: 100%;
    }

    .table-responsive td::before {
        content: attr(data-label);
        flex: 1;
        font-weight: bold;
        margin-right: 1rem;
    }

    .table-responsive td {
        margin-bottom: 0.5rem;
        border-top: 1px solid #dee2e6;
    }

    .table-responsive tr td:first-child {
        border-top: none;
    }
}



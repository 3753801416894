/* Recent Campaigns Card */
/* Recent Campaigns Card */
.recent-campaign-card {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 5px;
    width: 100%; /* Ensure it takes up the full width of the parent */
    max-width: 100%; /* Prevent overflow */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Optional card shadow */
    height: 100%; /* Fill available height */
}

/* Ensure the card container allows full width */
.col {
    display: flex; /* Flex layout if needed */
    justify-content: center;
    align-items: center;
    flex-grow: 1; /* Ensure it grows to fill the available space */
}

.mb-2 {
    margin-bottom: 8px; /* Keep the margin bottom as Bootstrap provides */
}


/* Summary Cards Section */
.summary-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 10px; /* Use gap instead of margin-right for equal spacing */
}

/* Individual Summary Card */
.summary-card {
    flex: 1 1 calc(50% - 10px); /* Flexible 50% width with gap */
    min-width: 150px;
    max-width: 100%;    /* Prevent overflow */
    background-color: #f9f9f9;
    border-radius: 5px;
    padding: 15px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Optional for card shadow */
    text-align: center;
}

/* Ensure No Extra Margin for the Last Card */
.summary-card:last-child {
    margin-right: 0;
}

/* Avoid text overflow in summary card */
.summary-card p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0; /* Ensure no extra spacing under the text */
}

/* Contact Section Chart Title */
.contacts-summary-row {
    margin-top: 20px;
}

/* Flex center alignment for contacts summary section */
.contacts-summary-row .col {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
    .summary-card {
        flex: 1 1 100%; /* Full width for small screens */
        margin-bottom: 15px; /* Add spacing between cards */
    }
}
